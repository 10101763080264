<template>
  <a-row>
    <a-col :sm="24" :md="6" :xl="6" :xxl="6" :style="{ marginBottom: '24px', textAlign: 'center' }">
      <div class="wrapper2">
        <span><b>{{ $t("route.RMAReadyToShip") }}</b></span>
      </div>
      <a-col :span="24" style="text-align: center;margin-top: 20px;">
        <a-row>
          <a-col :sm="24" :md="24" :xl="24" :span="12">
            <div class="wrapper2">
              <span :class="product_waiting > 10 ? 'badge2' : 'badge22'" style="font-size: 45px">{{ product_waiting }}</span>
            </div>
          </a-col>
        </a-row> 
      </a-col>
    </a-col>
    <a-col :sm="24" :md="6" :xl="6" :xxl="6" :style="{ marginBottom: '24px', textAlign: 'center' }">
      <div class="wrapper2">
        <span><b>{{ $t("route.RMAReadyToShip72") }}</b></span>
      </div>
      <a-col :span="24" style="text-align: center;margin-top: 20px;">
        <a-row>
          <a-col :sm="24" :md="24" :xl="24" :span="12">
            <div class="wrapper2">
              <span :class="product_waiting_72h > 10 ? 'badge3' : 'badge33'" style="font-size: 45px">{{ product_waiting_72h }}</span>
            </div>
          </a-col>
        </a-row> 
      </a-col>
    </a-col>
    <a-col :sm="24" :md="6" :xl="6" :xxl="6" :style="{ marginBottom: '24px', textAlign: 'center' }">
      <div class="wrapper2">
        <span><b>{{ $t("route.RMAWaitingForCustomer") }}</b></span>
      </div>
      <a-col :span="24" style="text-align: center;margin-top: 20px;">
        <a-row>
          <a-col :sm="24" :md="24" :xl="24" :span="12">
            <div class="wrapper2">
              <span :class="productWaitingForClient > 10 ? 'badge2' : 'badge22'" style="font-size: 45px">{{ productWaitingForClient }}</span>
            </div>
          </a-col>
        </a-row> 
      </a-col>
    </a-col>
    <a-col :sm="24" :md="6" :xl="6" :xxl="6" :style="{ marginBottom: '24px', textAlign: 'center' }">
      <div class="wrapper2">
        <span><b>{{ $t("route.RMAWaitingForCustomer1week") }}</b></span>
      </div>
      <a-col :span="24" style="text-align: center;margin-top: 20px;">
        <a-row>
          <a-col :sm="24" :md="24" :xl="24" :span="12">
            <div class="wrapper2">
              <span :class="productWaitingForClient_week > 10 ? 'badge3' : 'badge33'" style="font-size: 45px">{{ productWaitingForClient_week }}</span>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-col>
  </a-row>
</template>
<script>
  import { toRefs } from 'vue'
  import { useI18n } from 'vue-i18n'
  import state from "@/state/dashboard";

  export default {
    name: 'Dashboard',
    components: {},
    data () {
      return {}
    },
    setup() {
      const { t ,locale } = useI18n({ useScope: 'global' })

      const { dashboardState } = state();

      return {
        t,
        locale,
        ...toRefs(dashboardState)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .wrapper2 {
    display: flex;
    justify-content: center;
    align-items: center; 
  }

  .badge2 {
    display: inline-block;
    border-radius: 50%;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 55px;
    text-align: center;
    background: #86D067;
    color: #fefefe;
  }

  .badge22 {
    display: inline-block;
    border-radius: 50%;
    font-size: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 55px;
    text-align: center;
    background: #86D067;
    color: #fefefe;
  }

  .badge3 {
    display: inline-block;
    border-radius: 50%;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 55px;
    text-align: center;
    background: #FF4D4E;
    color: #fefefe;
  }

  .badge33 {
    display: inline-block;
    border-radius: 50%;
    font-size: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 55px;
    text-align: center;
    background: #FF4D4E;
    color: #fefefe;
  }

  .ant-table-row::v-deep {
    height: 31px!important;
  }
</style>