import useRmaRepositories from "@/composables/useRmaRepositories"
import state from "@/state/dashboard"

export default function useDashboardRepositories() {
  const { getDashboardStats, getDashboardCreateHistory } = useRmaRepositories()
  const { dashboardState } = state()

  const newCalDataForChart = async () => {
    dashboardState.x = []
    dashboardState.y = []
    dashboardState.wx = []
    dashboardState.wy = []
    const createCancelCloselist = await getDashboardCreateHistory()
    createCancelCloselist.forEach(v => {
      dashboardState.x.push(v.CreateDate)
      dashboardState.y.push(v.Total)
      if (dashboardState.wx.indexOf(v.week) == -1) {
        dashboardState.wx.push(v.week)
        dashboardState.wy.push(v.Total)
      } else {
        dashboardState.wy[dashboardState.wx.indexOf(v.week)] += v.Total
      }
    });
  }

  const newCalDataForPie = async () => {
    dashboardState.pieData = []
    const result = await getDashboardStats()
    let inStore = 0
    let transitToFactory = 0
    let inRepair = 0
    let transitToStore = 0
    let waitingForPickup = 0
    result.all.forEach(v => {
      if (v.RmaStatusId === 1 || v.RmaStatusId === 18) inStore += v.Total
      if (v.RmaStatusId === 2) transitToFactory += v.Total
      if (v.RmaStatusId > 2 && v.RmaStatusId < 14) inRepair += v.Total
      if (v.RmaStatusId === 14) transitToStore += v.Total
      if (v.RmaStatusId === 15) waitingForPickup += v.Total
      if (v.RmaStatusId === 1) dashboardState.product_waiting = v.Total
      if (v.RmaStatusId === 15) dashboardState.productWaitingForClient = v.Total
    })
    dashboardState.product_waiting_72h = result.product_waiting_72h[0].Total
    dashboardState.productWaitingForClient_week = result.product_waiting_for_client_1week[0].Total
    dashboardState.pieData = [
      inStore,
      transitToFactory,
      inRepair,
      transitToStore,
      waitingForPickup
    ]
  }

  return {
    newCalDataForChart,
    newCalDataForPie,
  }
}