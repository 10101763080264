import { ref, reactive } from "vue";

const dashboardState = reactive({
  x: 0,
  y: 0,
  wx: 0,
  wy: 0,
  pieData: [],
  product_waiting: 0,
  productWaitingForClient: 0,
  product_waiting_72h: 0,
  productWaitingForClient_week: 0
});

export default function dataState() {
  return {
    dashboardState
  };
}
