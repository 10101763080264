<template>
  <a-row>
    <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
      <a-card :loading="loading.intransit" :bordered="false" :title="$t('route.RMASentToWarehouse')" :style="{ height: '100%' }">
        <div class="ant-table-wrapper">
          <a-table
            rowKey="NoTracking"
            size="small"
            :columns="rmaSentToWarehouse"
            :dataSource="productSentToWarehouse"
            :pagination="{ pageSize: 5 }"
          ></a-table>
        </div>
      </a-card>
    </a-col>
    <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24">
      <a-card :loading="loading.intransit" :bordered="false" :title="$t('route.RMAIntransit')" :style="{ height: '100%' }">
        <div class="ant-table-wrapper">
          <a-table
            rowKey="NoTracking"
            size="small"
            :columns="dashboardColumns"
            :dataSource="productInTransit"
            :pagination="{ pageSize: 5 }"
          ></a-table>
        </div>
      </a-card>
    </a-col>
  </a-row>
</template>
<script>
  import { reactive, ref } from '@vue/reactivity'
  import { onMounted, computed } from 'vue'
  import useRmaRepositories from "@/composables/useRmaRepositories"
  import { useI18n } from 'vue-i18n';

  export default {
    name: 'Dashboard',
    components: {},
    data () {
      return {}
    },
    setup() {
      const { t ,locale } = useI18n({ useScope: 'global' })
      const loading = reactive({
        waitingPackage: true,
        latePackage: true,
        inrepair: true,
        intransit: true,
        waitingPickup: true,
      })

      const { getRMASentToWarehoure, getRMAInTransitToShop } = useRmaRepositories()
      const productInTransit = ref([])
      const productSentToWarehouse = ref([])
      const rmaSentToWarehouse = computed(() => {
        return [
          {
            dataIndex: 'ShippingDate',
            title: t('route.ShippingDate'),
            width: 100
          },
          {
            dataIndex: 'Count',
            title: t('route.RMACount'),
            width: 100
          },
          {
            dataIndex: 'NoTracking',
            title: t('route.TrackingCount'),
            width: 120
          }
        ]
      })
      const dashboardColumns = computed(() => {
        return [
          {
            dataIndex: 'ShippingDate',
            title: t('route.ShippingDate'),
            width: 100
          },
          {
            dataIndex: 'NoTracking',
            title: t('route.TrackingCount'),
            width: 100
          },
          {
            dataIndex: 'Count',
            title: t('route.RMACount'),
            width: 50
          },
        ]
      })

      onMounted(async () => {
        productSentToWarehouse.value = await getRMASentToWarehoure()
        productInTransit.value = await getRMAInTransitToShop()
        loading.intransit = false
      })

      return {
        loading,
        productInTransit,
        productSentToWarehouse,
        t,
        locale,
        rmaSentToWarehouse,
        dashboardColumns
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>